<template>
  <form-wrapper ref="form" reference="blockchainConfigForm" boxed form-title="Blockchain" @update:valid="$emit('update:valid', true)">
    <template #fields>
      <v-row>
        <v-col cols="12">
          <FormWalletComponent @isValid="isValid = $event" />
        </v-col>
        <v-col cols="12">
          <WalletsTable />
        </v-col>
      </v-row>
    </template>
  </form-wrapper>
</template>

<script>
  import WalletsTable from "@/components/UserOptions/Blockcahin/WalletsTable";
  import FormWalletComponent from "@/components/UserOptions/Blockcahin/WalletFormComponent";
  import FormWrapper from "@/components/common/FormWrapper";

  export default {
    components: { FormWrapper, FormWalletComponent, WalletsTable },
    data() {
      return {
        isValid: false
      };
    },
    beforeDestroy() {
      this.$refs.form.$refs.blockchainConfigForm.reset();
      console.log("BlockchainConfigTab Destroyed");
    }
  };
</script>

<style scoped>
  .display-field {
    padding: 8px;
    background-color: #f5f5f5;
    border-radius: .2rem;
    display: flex;
    align-items: center;
  }
</style>
