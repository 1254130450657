<template>
  <form-wrapper ref="form" reference="generalConfigForm" boxed :form-title="$t('general.titles.general')" @update:valid="$emit('update:valid', $event)">
    <template #fields>
      <v-col cols="6">
        <v-label>{{ $t('company.titles.decimalNumbers') }}</v-label>
        <v-text-field
          :placeholder="(company.traceability.structure.number.afterPoint === null || company.traceability.structure.number.afterPoint === '') ? $t('company.titles.defaultValue') : ''"
          id="afterPoint"
          dense
          outlined
          color="primary"
          type="number"
          clearable
          :rules="compareMax(0,5)"
          v-model="company.traceability.structure.number.afterPoint"
        />
      </v-col>
    </template>
  </form-wrapper>
</template>

<script>
  import FormWrapper from "@/components/common/FormWrapper";
  import { mapGetters } from "vuex"

  export default {
    components: { FormWrapper },
    props: {
      company: {
        type: Object,
        required: true,
      },
      isEditable: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapGetters({
        compareMax: 'general/compareMax'
      }),
    },
    beforeDestroy() {
      this.$refs.form.$refs.generalConfigForm.reset();
      console.log("GeneralConfigTab Destroyed");
    }
  };
</script>

<style scoped>
  .display-field {
    padding: 8px;
    background-color: #f5f5f5;
    border-radius: .2rem;
    display: flex;
    align-items: center;
  }
</style>
