<template>
  <form-wrapper ref="form" reference="exportConfigForm" boxed :form-title="$t('general.buttons.export').slice(0,1) + $t('general.buttons.export').slice(1,$t('general.buttons.export').length)" @update:valid="$emit('update:valid', $event)">
    <template #fields>
      <v-col cols="12" md="6">
        <v-label>{{ $t('modules.name.Record') }}</v-label>
        <v-text-field
          :placeholder="(company.export.recordExportLimit === null || company.export.recordExportLimit === '') ? $t('company.titles.defaultExportRecordsValue') : ''"
          id="exportRecordLimit"
          dense
          outlined
          color="primary"
          type="number"
          clearable
          v-model="company.export.recordExportLimit"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-label>{{ $t('modules.name.Survey') }}</v-label>
        <v-text-field
          :placeholder="(company.export.inspectionExportLimit === null || company.export.inspectionExportLimit === '') ? $t('company.titles.defaultExportInspectionsValue') : ''"
          id="inspectionExportLimit"
          dense
          outlined
          color="primary"
          type="number"
          clearable
          v-model="company.export.inspectionExportLimit"
        />
      </v-col>
    </template>
  </form-wrapper>
</template>

<script>
  import FormWrapper from "@/components/common/FormWrapper";

  export default {
    components: { FormWrapper },
    props: {
      company: {
        type: Object,
        required: true,
      },
      isEditable: {
        type: Boolean,
        default: true
      }
    },
    beforeDestroy() {
      this.$refs.form.$refs.exportConfigForm.reset();
      console.log("ExportConfigTab Destroyed");
    }
  };
</script>

<style scoped>
  .display-field {
    padding: 8px;
    background-color: #f5f5f5;
    border-radius: .2rem;
    display: flex;
    align-items: center;
  }
</style>
